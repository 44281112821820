import { render, staticRenderFns } from "./LanguageComponent.vue?vue&type=template&id=27896e62&scoped=true"
import script from "./LanguageComponent.vue?vue&type=script&lang=js"
export * from "./LanguageComponent.vue?vue&type=script&lang=js"
import style0 from "./LanguageComponent.vue?vue&type=style&index=0&id=27896e62&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27896e62",
  null
  
)

export default component.exports